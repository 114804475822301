import React from "react"
import BlogPostThumbnail from "../components/BlogPostThumbnail"

const RelatedPosts = props => {
  return (
    <div className="my-16">
      <h3 className="text-center text-gray-900 mb-4 display-sm font-semibold">
        Related Posts
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4">
        {props.articles.slice(0, 3).map((x, index) => {
          return (
            <BlogPostThumbnail
              key={index}
              blogLink={x.uri}
              blogTitle={x.title}
              blogExcerpt={x.excerpt}
              categories={x.categories.nodes}
              imageURL={x.featuredImage.node.mediaDetails.sizes[5].sourceUrl}
            />
          )
        })}
      </div>
    </div>
  )
}

export default RelatedPosts
