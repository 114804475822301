import React, { useState } from "react"

const CalculateCalories = props => {
  const [weight, setWeight] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [weightType, setWeightType] = useState("pounds")
  const [caloriesBurned, setCaloriesBurned] = useState(0)

  const calculate = () => {
    setCaloriesBurned(
      Math.floor(
        ((props.met_value *
          (weightType === "pounds" ? weight / 2.2 : weight) *
          3.5) /
          200) *
          minutes
      )
    )
  }

  return (
    <div className="">
      <div>
        <h2 className="">Calories burned {props.title} Calculator</h2>
        <div className="flex flex-col mb-4">
          <label
            className="mb-2 uppercase font-bold text-lg text-gray-900"
            htmlFor="weight"
          >
            Weight
          </label>
          <input
            className="border py-2 px-3 text-gray-900"
            onChange={e => setWeight(e.target.value)}
            type="number"
            placeholder="Weight"
            name="weight"
          />
        </div>

        <div
          className="mb-4 flex flex-row"
          onChange={e => setWeightType(e.target.value)}
        >
          <div class="flex items-center mr-4">
            <input
              id="pounds"
              defaultChecked="checked"
              type="radio"
              name="weightType"
              value="pounds"
              className="hidden"
            />
            <label for="pounds" class="flex items-center cursor-pointer">
              <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              LBS
            </label>
          </div>

          <div className="flex items-center mr-4">
            <input
              id="kilos"
              value="kilos"
              type="radio"
              name="weightType"
              className="hidden"
            />
            <label for="kilos" className="flex items-center cursor-pointer">
              <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              KG
            </label>
          </div>
        </div>

        <div className="flex flex-col mb-4">
          <label
            className="mb-2 uppercase font-bold text-lg text-gray-900"
            htmlFor="minutes"
          >
            Minutes
          </label>
          <input
            className="border py-2 px-3 text-gray-900"
            type="number"
            placeholder="Minutes"
            onChange={e => setMinutes(e.target.value)}
            name="minutes"
          />
        </div>

        <button
          className="text-lg text-center text-white-900 w-full bg-primary-600 hover:bg-primary-700 transition duration-500 ease-in-out py-3"
          onClick={calculate}
        >
          Calculate Calories
        </button>
        <div className="my-8 text-center">
          <span className="display-sm font-semibold">Calories Burned</span>
          <span className="display-sm">🔥</span>:{" "}
          <span className="display-sm font-semibold">{caloriesBurned}</span>
        </div>
      </div>
    </div>
  )
}

export default CalculateCalories
